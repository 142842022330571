// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/core';

import { fontFamily } from '@atlaskit/theme/constants';
import {
	LEFT_PANEL_WIDTH,
	RIGHT_PANEL_WIDTH,
	LEFT_SIDEBAR_WIDTH,
	RIGHT_SIDEBAR_WIDTH,
	TOP_NAVIGATION_HEIGHT,
} from '@atlaskit/page-layout';

// SSR
export const SSR_LAYOUT_DATA_ATTR = 'data-main-content';
export const SSR_IFRAME_ATTR = 'data-flex-container';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LayoutMainWrapper = styled.div<{
	showBreadcrumbNavHeader?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ showBreadcrumbNavHeader }) => ({
	display: 'contents',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'#AkMainContent': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `calc(100vw - ${LEFT_PANEL_WIDTH} - ${LEFT_SIDEBAR_WIDTH} - ${RIGHT_PANEL_WIDTH} - ${RIGHT_SIDEBAR_WIDTH}) !important`,
		transition: `width 300ms cubic-bezier(0.2, 0, 0, 1) 0s`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingTop: showBreadcrumbNavHeader ? '60px' : '0px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& #jira-frontend': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `calc(100vh - ${TOP_NAVIGATION_HEIGHT})`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		width: `calc(
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */ ''}
      100vw - ${LEFT_PANEL_WIDTH} - ${LEFT_SIDEBAR_WIDTH} - ${RIGHT_PANEL_WIDTH} - ${RIGHT_SIDEBAR_WIDTH}
    ) !important`,
		transition: 'width 300ms cubic-bezier(0.2, 0, 0, 1) 0s',
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ZIndexWrapper = styled.div<{
	zIndex: number;
	showQuickStart: boolean;
}>`
	z-index: ${(props) => props.zIndex};
	nav[aria-label='space navigation'] {
		min-width: 0px;
	}
	display: ${({ showQuickStart }) => (showQuickStart ? 'block' : 'none')};
`;

// This uses CCS variables from atlaskit/page-layout to make sure
// our SSR pages are positioned inside the new layoutManager despite being siblings in the HTML
export const StyleFixSSRContentForNewLayoutWithRightSidebar = (totalHeight: number) => {
	// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
	return css`
		#ui-global-sidebar-section {
			z-index: auto !important;
			width: 100%;
		}

		[${SSR_LAYOUT_DATA_ATTR}], [${SSR_IFRAME_ATTR}] {
			width: calc(
				100% - ${LEFT_PANEL_WIDTH} - ${LEFT_SIDEBAR_WIDTH} - ${RIGHT_PANEL_WIDTH} -
					${RIGHT_SIDEBAR_WIDTH}
			) !important;
			top: ${totalHeight + 2}px !important;
			position: absolute;
			left: calc(${LEFT_PANEL_WIDTH} + ${LEFT_SIDEBAR_WIDTH});
			z-index: auto;
			box-sizing: border-box;

			&#admin-content {
				// admin nav -> manage apps
				.upm-manage {
					#upm-manage-type, // app type dropdown filter
        .upm-details .upm-plugin-info .upm-column {
						// expand accordion to show app detail -> 3 column info table
						z-index: 1;
					}
				}
			}
		}
		[${SSR_IFRAME_ATTR}] > [${SSR_LAYOUT_DATA_ATTR}] {
			display: none;
		}
	`;
};

//CSS hack for code block
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const styleCodeBlockFix = css`
	.code-block.right-shadow {
		z-index: 2;
	}
`;

//CSS hack for noPrint
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const styleNoPrintNav = css`
	[data-ds--page-layout--slot='top-navigation'],
	[data-ds--page-layout--slot='left-sidebar'] {
		@media print {
			display: none !important;
		}
	}
`;

//To make sure we are using the same font family set by atlassian-frontend theme.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const fontFamilyCLSFix = css`
	.PageContent {
		font-family: ${fontFamily()};
	}
`;

//to remove cls from more action dropdown
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const moreActionDropdownCLSFix = css`
	[data-testid='content-tools-dropdown-menu--content'] {
		width: 220px;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ContentRightSidePanel = styled.div`
	height: 100%;
	width: 100%;

	& > div {
		height: 100%;
		width: 100%;

		& > div {
			position: static;
		}
	}
`;
