import { useSessionData, AccessStatus } from '@confluence/session-data';
import { isOnboardingExpEnabled } from '@confluence/growth-experiment-helpers';
import { useMigratedUser } from '@confluence/onboarding-helpers/entry-points/hooks/useMigratedUser';
import { useWindowSize } from '@confluence/dom-helpers';
import { useIsSiteAdmin } from '@confluence/onboarding-helpers/entry-points/hooks/useIsSiteAdmin';
import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ORIGIN_ATTRIBUTES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useRouteName } from '@confluence/route-manager';

import { QS_WINDOW_WIDTH_THRESHOLD } from './constants';
import { isNameAllowed } from './isNameAllowed';

export const useQuickstartEligible = () => {
	const { featureFlagClient, userId, accessStatus } = useSessionData();
	const { isSiteAdmin } = useIsSiteAdmin();

	const { isMigratedUser, isMigratedUserReady } = useMigratedUser();

	const { data } = useGetOnboardingState([ORIGIN_ATTRIBUTES]);
	const state = deserializeState(data);

	const checkAllowedRoutes = {
		selector: (name) => isNameAllowed(name),
	};

	const isAllowlistPath = useRouteName(checkAllowedRoutes);
	const { width: windowWidth } = useWindowSize();

	const isWidthThresholdMet = windowWidth >= QS_WINDOW_WIDTH_THRESHOLD;
	const isLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;
	const isAnonymousUser = !userId || !isLicensed;
	const isAdmin = isSiteAdmin;
	const isTarget = isAllowlistPath && !isAnonymousUser && isWidthThresholdMet;

	// -------- JSW XFlow Quickstart Targeting
	let isJSWXFlowUser: boolean;
	const attributes = state?.attributes ?? '{}';

	try {
		isJSWXFlowUser = JSON.parse(attributes)?.originProduct === 'jira';
	} catch (e) {
		if (process.env.NODE_ENV !== 'production') {
			// eslint-disable-next-line
			console.error('Failed to parse JSON object from state.attributes', attributes);
		}
		isJSWXFlowUser = false;
	}

	// Master FF to quickly enable / disable all Onboarding Experiences
	const isOnboardingEligible = isOnboardingExpEnabled(featureFlagClient, false);

	const isNthUserQSV2Eligible = isOnboardingEligible && isTarget && !isAdmin;

	const isAdminQuickStartV2Eligible = isOnboardingEligible && isTarget && isAdmin;

	const isMigrationsQuickstartEligible =
		isOnboardingEligible && isTarget && !isAdmin && isMigratedUserReady && isMigratedUser;

	const isJswXflowUserQSEligible = isJSWXFlowUser && isOnboardingEligible && isTarget;

	const isQuickstartEligible = () => {
		return (
			isOnboardingEligible && // Master FF
			isTarget && // Allow list paths
			!isSiteAdmin &&
			// core QS experiment FFs
			(isJswXflowUserQSEligible ||
				isAdminQuickStartV2Eligible ||
				isMigrationsQuickstartEligible ||
				isNthUserQSV2Eligible)
		);
	};

	return {
		isTarget,
		isAdmin,
		isOnboardingEligible,
		isQuickstartEligible,
		isNthUserQSV2Eligible,
		isAdminQuickStartV2Eligible,
		isMigrationsQuickstartEligible,
		isJswXflowUserQSEligible,
	};
};
