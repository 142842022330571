import type { FC } from 'react';
import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';

import { ContainerSkeleton } from './ContainerSkeleton';
import { SPACE_NAVIGATION_METRIC } from './perf.config';
import type { SpaceNavigationProps } from './SpaceNavigation';

export const SpaceNavigationLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpaceNavigation" */ './SpaceNavigation'))
			.SpaceNavigation,
	loading: () => <ContainerSkeleton loader />,
});

export const SpaceNavigation: FC<SpaceNavigationProps> = (props) => (
	<>
		<PageSegmentLoadStart metric={SPACE_NAVIGATION_METRIC} />
		<SpaceNavigationLoader {...props} />
	</>
);

export const SpaceNavigationLoadingSkeleton: FC = () => <ContainerSkeleton loader />;
